import React, { useEffect, useState } from 'react'
import ExportToExcel from '../../../components/exportExcel/ExportToExcel'
import { get_taux } from '../../../services/Rapports/RapportsApi'
import PdvVisiteDistinct from './Tableaux/PdvVisiteDistinct'
import PortfeuillePdv from './Tableaux/PortfeuillePdv'
import Operation from './Tableaux/Operation'

export default function TableauAnalyse({ countPDV_planifieee, t, listeDash , pdv_planifiee,releve, pl ,promot , rupture ,date1,date2 }) {
    const [list , setlist]=useState([])
    const [showpdv_visite_distinct,setshowpdv_visite_distinct]=useState(false)
    const [showportfeuille_pdv_click,setshowportfeuille_pdv_click]=useState(false)
    const [showoperation,setshowoperation]=useState(false)

    const [selectedElement,setselectedElement]=useState()
    const findElement = (type , user_code)=>{
       /*  if(type === "pdv_planifiee"){
            const liste = pdv_planifiee?.filter((el) => el.user_code === user_code )
            if(liste[0]?.pdv_planifiee !== undefined){
                return liste[0]?.pdv_planifiee
            }else return 0
        }else */ if (type === "rupture"){
            const liste = rupture?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_rupture !== undefined){
                return liste[0]?.total_rupture
            }else return 0
        }else if (type === "releve"){
            const liste = releve?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_relevePrix !== undefined){
                return liste[0]?.total_relevePrix
            }else return 0
        }else if (type === "promotion"){
            const liste = promot?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_promotions !== undefined){
                return liste[0]?.total_promotions
            }else return 0
        }else if (type === "pl"){
            const liste = pl?.filter((el) => el.user_code === user_code )
            if(liste[0]?.total_pl !== undefined){
                return liste[0]?.total_pl
            }else return 0
        }
    }
  
    function convertToSeconds(timeString) {
        if(timeString){
            const [hours, minutes] = timeString?.split(':').map(Number);
      
            const totalSeconds = hours * 3600 + minutes * 60;
          
            return totalSeconds;
        }
       
      }
    
    useEffect(()=>{
        if(date1&&date2){
            get_taux(date1,date2).then(res=>{
                const l =res.data.data.map(item => {
                    const element ={...item}
                    const heure_debut_sc =convertToSeconds(element.heure_debut)
                    const heure_fin_sc =convertToSeconds(element.heure_fin)
                    const nbr_heure_demande_sc =convertToSeconds(element.nbr_heure_demande)
                    element.nbr_heure_demande =  element.nbr_heure_demande?.split(":").slice(0, 2).join(":");
                    element.heure_debut =  element.heure_debut?.split(":").slice(0, 2).join(":");
                    element.heure_fin =  element.heure_fin?.split(":").slice(0, 2).join(":");

                    element.tauxpointage =Math.round(((heure_fin_sc-heure_debut_sc) /nbr_heure_demande_sc)*100 ||0);
                     return element
                });
                setlist(l) 
               
            
            })
        }
      
    },[date1,date2])

    return (
        <div className="card-body">
            <span className="fs-12 text-azure mb-3"><i className='icon icon-exclamation mr-1' /> {t('dashboard.formatheure')}</span>
            <div className='float-right'>
                <ExportToExcel id="table-to-xls" namefile="rapport_dashboard" t={t} />
            </div>
            <div className="table-responsive  product-datatable " style={{ overflow: 'auto', height: '600px' }}>
                <table id="table-to-xls" className="table  table-bordered  " >
                    <thead className="text-center font-weight-normal" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#f7f9fc" }} >

                        <tr >
                            <td className="w-5p fs-10 text-center "> {t('dashboard.Merchandiseur')} </td>
                            <td className="w-10p fs-10">{t('dashboard.heuredebut')}</td>
                            <td className="w-10p fs-10">{t('dashboard.heureFin')}</td>
                            <td className="w-10p fs-10">{t('dashboard.heuredemande')}</td>
                            <td className="w-10p fs-10">Tx% </td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvplanifiee')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvvisite')}</td>
                            <td className="wd-10p fs-10">Tx%</td>
                            <td className="wd-10p fs-10">{t('Routings.NPDV')}</td>
                            <td className="wd-10p fs-10">{t('dashboard.pdvvisite')}</td>
                            <td className="wd-10p fs-10">Tx%</td>
                            <td className="wd-10p fs-10">{t('dashboard.Operation')}</td>
                           
                        </tr>
                    </thead>

                    <tbody>
                        {list?.map((items, keys) => (
                            <tr className='text-center fs-10' key={keys}>
                                <td className='text-center'> <b> {items.nom} </b>
                                    {items.validation === 1 ? <span className="ml-1 badge badge-success  mr-1 mb-1 mt-1">{t('Congé')}</span> : (items.validation === 0 ? <span className=" badge badge-danger  mr-1 mb-1 mt-1"><a href='/users/conge' className='text-white'> {t("Demande Congé")}</a> </span> : null)}
                                </td>

                                <td className='fs-10'>{items.heure_debut}</td>
                                <td className='fs-10'>{items.heure_fin}</td>
                                <td className='fs-10'>{items.nbr_heure_demande}</td>
                                <td>{items.tauxpointage} %</td>

                                <td className='fs-10' >{items.nbr_pdv_jour}</td>
                                <td className='fs-10' onClick={()=>{setselectedElement(items.code);setshowpdv_visite_distinct(true)}} ><b  className='souligne' style={{ cursor: 'pointer' }} >{items.pdv_visite_distinct}</b></td>
                                <td>{Math.round(items.pdv_visite_distinct/items.nbr_pdv_jour||0)}% </td>

                                <td className='fs-10' onClick={()=>{setselectedElement(items.code);setshowportfeuille_pdv_click(true)}}><b  className='souligne' style={{ cursor: 'pointer' }} >{items.portfeuille_pdv}</b></td>
                                <td className='fs-10' onClick={()=>{setselectedElement(items.code);setshowpdv_visite_distinct(true)}}><b   className='souligne' style={{ cursor: 'pointer' }} >{items.pdv_visite_distinct}</b></td>
                                <td>{Math.round((items.pdv_visite_distinct/items.portfeuille_pdv)*100 ||0)}% </td>

                                <td className='fs-10' onClick={()=>{setselectedElement(items.code);setshowoperation(true)}}><b  className='souligne' style={{ cursor: 'pointer' }} >{items.operation}</b></td>
                               
                         </tr>
                        ))}
                    </tbody>
                </table>
                {showpdv_visite_distinct && (
                <PdvVisiteDistinct
                    t={t}
                    user_code={selectedElement}
                    date1={date1}
                    date2={date2}
                    onClose={() => setshowpdv_visite_distinct(false)}
                />)}
                {showportfeuille_pdv_click && (
                    <PortfeuillePdv
                        t={t}
                        user_code={selectedElement}
                        onClose={() => setshowportfeuille_pdv_click(false)}
                    />
            )}
             {showoperation && (
                    <Operation
                        t={t}
                        user_code={selectedElement}
                        date1={date1}
                        date2={date2}
                        onClose={() => setshowoperation(false)}
                    />
            )}
            </div>
        </div>
    )
}
