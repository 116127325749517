import React, { useEffect, useState } from 'react';
import { pdv_visite_distinct_click, portfeuille_pdv_click } from '../../../../services/Rapports/RapportsApi';
import moment from 'moment';
import SqaureLoader from '../../../../components/Loader/SqaureLoader';

const PortfeuillePdv = ({ t, user_code, onClose }) => {
    const [listePdvUser, setListePdvUser] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if(user_code){
        portfeuille_pdv_click(user_code)
            .then((result) => {
                if (result?.status === 200) {
                    setListePdvUser(result?.data?.data);
                }
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [user_code]);

    return (
        <div className="modal fade show" id="pdvVisité1" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="pdvVisité1" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ width: "max-content" }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="pdvVisité"> <i className='fe fe-tag text-azure mr-1' /> {t('PDV Portfeuille')}   </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {loading ? <SqaureLoader /> : (
                            <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                                <table className="table table-bordered">
                                    <thead className="text-center bg-bleu  fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "#1A4BE3" }}>
                                        <tr>
                                            <td className='fs-10'>#</td>
                                            <td className="wd-15p fs-10">{t('Code')}</td>
                                            <td className="wd-15p fs-10">{t('Magasin')}</td>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listePdvUser?.map((items, keys) => (
                                            <tr key={keys} className="text-center fs-10">
                                                <td className='fs-10'>{keys + 1}</td>
                                                <td>{items.code}</td>
                                                <td>{items.nompdv}</td>
                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfeuillePdv;
