import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { operation_click } from '../../../../services/Rapports/RapportsApi';
import SqaureLoader from '../../../../components/Loader/SqaureLoader';

export default function Operation({ t, user_code, date1, date2, onClose }) {
        const [listePdvUser, setListePdvUser] = useState([]);
        const [loading, setLoading] = useState(true);
    
        useEffect(() => {
            setLoading(true);
            if(user_code){
                operation_click(date1, date2, user_code)
                .then((result) => {
                    if (result?.status === 200) {
                        setListePdvUser(result?.data?.data);
                    }
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    setLoading(false);
                });
            }
           
        }, [user_code, date1, date2]);
    
        return (
            <div className="modal fade show" id="pdvVisité1" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="pdvVisité1" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ width: "max-content" }}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="pdvVisité"> <i className='fe fe-tag text-azure mr-1' /> {t('Operation')}   </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => onClose()}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loading ? <SqaureLoader /> : (
                                <div className="table-responsive product-datatable" style={{ overflow: 'auto', height: '500px' }}>
                                    <table className="table table-bordered">
                                        <thead className="text-center bg-bleu fs-10" style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "red !important" }}>
                                            <tr>
                                                <td className='fs-10'>#</td>
                                                <td className="wd-15p fs-10">{t('nompdv')}</td>
                                                <td className="wd-15p fs-10">{t('libelle')}</td>
                                                <td className="wd-15p fs-10">{t('emplacement')}</td>
                                                <td className="wd-15p fs-10">{t('date')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listePdvUser?.map((items, keys) => (
                                                <tr key={keys} className="text-center fs-10">
                                                    <td className='fs-10'>{keys + 1}</td>
                                                    <td>{items.nompdv}</td>
                                                    <td>{items.libelle}</td>
                                                    <td>{items.emplacement}</td>
                                                    <td>{moment(items.date).format('DD/MM/YYYY')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    

