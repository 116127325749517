import moment from 'moment'
import React from 'react'

export default function TableauStock({liste , t}) {
  return (
    <table className="table  table-bordered " id="table-to-xls" >
    <thead className="text-center bg-azure-lighter">

        <tr>
            <th className="w-15p fs-10">{t('rapports.PRODUIT')}</th>
            <th className="w-15p fs-10">{t('Magasins.Magasins')}</th>
            <th className="w-15p fs-10">{t('Magasins.Zone')}</th>
            <th className="w-15p fs-10">{t('Magasins.MERCH')}</th>
            <th className="w-15p fs-10">{t('dashboard.Date')}</th>
            <th className="w-15p fs-10">{t('rapports.STOCKDEBUT')}</th>
            <th className="w-15p fs-10">{t('Stock Rajout')}</th>
            <th className="w-15p fs-10">{t('rapports.STOCKFIN')}</th>
            <th className="w-15p fs-10">{t('rapports.variation')} </th>

        </tr>
    </thead>
    <tbody className='text-center'>
        {liste?.map((items, keys) => (
            <tr key={keys}   className={
                keys % 2 === 1 ? "fs-10 bg-azure-lightest text-center" : "fs-10 text-center"
            } >
                <td>{items.libelle} </td>
                <td>{items.magasin} </td>
                <td>{items.zone_code} </td>
                <td>{items.user} </td>
                <td>{moment(items.date).format("DD/MM/YYYY")} </td>
                <td>{items.stock_debut !== 0 ? <b className='text-azure'>{items.stock_debut}</b>:items.stock_debut } </td>
                <td>{items.stock_rajout !== 0 ? <b className='text-azure'>{items.stock_rajout} </b> :items.stock_rajout} </td>
                <td>{items.stock_fin !== 0 ? <b className='text-azure'>{items.stock_fin}</b>:items.stock_fin } </td>
                <td>{parseInt(items.stock_debut - items.stock_fin) !== 0 ? <b className='text-azure'>{parseInt(items.stock_debut - items.stock_fin)}</b>:parseInt(items.stock_debut - items.stock_fin) } </td>
            </tr>
        ))}
    </tbody>
</table>
  )
}
